<template>
  <div class="container" id="main-page">
    <div class="w-11/12 md:w-5/6 m-auto pt-4">
      <div class="container p-2">
        <div class="row justify-content-center row-header">
          <div class="col-12 container-back mb-3">
            <div class="history-header">
              <Back />
              <span> Kebijakan Privasi</span>
            </div>
          </div>

          <div class="col-12 about-us bg-white border-lg">
            <div class="text_g">
              Kerahasian Informasi Pribadi adalah hal yang terpenting bagi PT.
              Solusi Desain Bangsa (“Kami”). Kami berkomitmen untuk melindungi
              dan menghormati privasi pengguna (“Anda”) saat mengakses dan
              menggunakan aplikasi, situs web (www.sekolahdesain.id dan situs
              web lain yang Kami kelola), fitur, teknologi, konten dan produk
              yang Kami sediakan (selanjutnya, secara Bersama-sama disebut
              sebagai “Platform”),<br />
            </div>
            <div class="text_g">
              Kebijakan Privasi ini mengatur landasan dasar mengenai bagaimana
              Kami menggunakan informasi pribadi yang Kami kumpulkan dan/atau
              atau Anda berikan (“Informasi Pribadi”). Kebijakan Privasi ini
              berlaku bagi seluruh pengguna Platform, kecuali diatur pada
              Kebijakan Privasi yang terpisah. Mohon membaca Kebijakan Privasi
              Kami dengan seksama sehingga Anda dapat memahami pendekatan dan
              cara Kami dalam menggunakan informasi tersebut.
            </div>
            <div class="text_g">
              Kebijakan Privasi ini mencakup hal-hal sebagai berikut:
            </div>
            <div class="text_g2">
              A. Informasi Pribadi yang Dapat Kami Kumpulkan <br />
              B. Penggunaan Informasi Pribadi
              <br />
              C. Pengungkapan Informasi Pribadi
              <br />
              D. Penyimpanan Informasi Pribadi
              <br />
              E. Hak Anda
              <br />
              F. Kebijakan Cookies
              <br />
              G. Pengakuan dan Persetujuan
              <br />
              H. Materi Pemasaran
              <br />
              I. Perubahan dalam Kebijakan Privasi Kami
              <br />
              J. Cara Menghubungi Kami
            </div>

            <div class="text_g">
              Dengan mengunjungi dan/atau mendaftar Akun pada Platform Kami,
              Anda dan/atau orang tua, wali atau pengampu Anda (jika Anda
              berusia dibawah 18 (delapan belas) tahun) menerima dan menyetujui
              pendekatan dan cara-cara yang digambarkan dalam Kebijakan Privasi
              ini
            </div>
            <br />
            <span class="">
              A. INFORMASI PRIBADI YANG DAPAT KAMI KUMPULKAN
            </span>
            <br />
            <div class="text_g">
              Kami dapat mengumpulkan Informasi Pribadi berupa:
            </div>
            <div class="text_g">
              a. Informasi yang Anda berikan.&nbsp;Anda dapat memberikan
              informasi melalui formulir elektronik pada Platform Kami maupun
              dengan berkorespondensi melalui telepon, surat elektronik, dan
              sebagainya. Informasi ini meliputi informasi yang Anda berikan
              ketika mendaftar pada Platform Kami, berlangganan layanan Kami,
              mencari produk, berpartisipasi dalam diskusi online atau fungsi
              media sosial lain pada Platform Kami, mengikuti kompetisi,
              promosi, atau survei, serta ketika Anda melaporkan masalah dengan
              Platform Kami. Informasi yang Anda berikan dapat meliputi nama,
              alamat, alamat surat elektronik, nomor telepon,informasi finansial
              dan kartu kredit, deskripsi pribadi, foto, dan data lainnya. Kami
              dapat meminta Anda untuk melakukan verifikasi terhadap informasi
              yang Anda berikan, untuk memastikan akurasi dari informasi
              tersebut.
            </div>
            <div class="text_g">
              b. Informasi yang Kami kumpulkan.&nbsp;Untuk setiap kunjungan Anda
              ke Platform Kami, Kami dapat mengumpulkan informasi berikut secara
              otomatis:
            </div>

            <div class="text_g">
              I. Informasi teknis, meliputi alamat Protokol Internet (IP
              address) yang digunakan untuk menghubungkan komputer Anda dengan
              internet, informasi log in Anda, jenis dan versi perambah
              (browser) yang digunakan, pengaturan zona waktu, jenis dan versi
              ekstensi perambah (browser plug-in), system operasi dan platform;
            </div>

            <div class="text_g">
              II. Informasi tentang kunjungan Anda, termasuk daftar lengkap
              Lokator Sumber Seragam (Uniform Resource Locators atau URL) yang
              dikunjungi menuju, melalui, dan dari Platform Kami (termasuk
              tanggal dan waktu); produk yang Anda lihat atau cari; waktu respon
              halaman, masalah pengunduhan, lama kunjungan pada halaman
              tertentu, informasi interaksi pada halaman (seperti pengguliran,
              klik, maupun pergerakan tetikus), metode yang digunakan untuk
              meninggalkan situs, serta nomor telepon yang digunakan untuk
              menghubungi layanan pelanggan Kami.
            </div>

            <div class="text_g">
              III.Data nilai Anda, termasuk namun tidak terbatas pada hasil
              ujian Anda yang diperoleh melalui Platform, serta data akademis
              lain.
            </div>

            <div class="text_g">
              c.Informasi yang Kami terima dari sumber lain.&nbsp;Kami dapat
              menerima informasi jika Anda menggunakan situs lain yang Kami
              operasikan atau layanan lain yang Kami sediakan. Kami juga bekerja
              sama dengan pihak ketiga (termasuk, namun tidak terbatas pada
              misalnya, mitra bisnis, sub-kontraktor dalam pelayanan teknis,
              jasa pembayaran dan pengantaran, jaringan periklanan, penyedia
              analisis, penyedia pencarian informasi, serta agen acuan kredit)
              (“Mitra Kami”) dan dapat menerima informasi dari mereka. Kami akan
              mengambil langkah-langkah dalam batas kewajaran untuk melakukan
              verifikasi terhadap informasi yang Kami dapatkan dari sumber lain
              sesuai dengan Peraturan yang Berlaku.
            </div>
            <br />
            <span class="">B. PENGGUNAAN INFORMASI PRIBADI</span>
            <div class="text_g">
              Kami menggunakan Informasi Pribadi dengan cara-cara berikut:
              a.Informasi yang Anda berikan. Kami akan menggunakan informasi
              ini: <br />
              <div class="text_g">
                i.untuk menjalankan kewajiban Kami dalam menyediakan informasi,
                produk, dan jasa kepada Anda;
              </div>

              <div class="text_g">
                ii.untuk menyediakan informasi terkait produk dan jasa lain yang
                Kami tawarkan; guna menyediakan Anda, atau mengizinkan pihak
                ketiga untuk menyediakan Anda, informasi tentang produk dan jasa
                yang Kami anggap dapat menarik minat Anda. Jika Anda adalah
                pelanggan lama, Kami dapat menghubungi Anda secara elektronik
                atau cara-cara lain dengan informasi tentang produk dan jasa
                Kami. Jika Anda adalah pelanggan baru, dan di mana Kami
                mengizinkan pihak ketiga untuk menggunakan Data Pribadi Anda,
                Kami (atau mereka) dapat menghubungi Anda secara elektronik
                hanya jika Anda sudah memberikan persetujuan.
              </div>

              <div class="text_g">
                iii.untuk memberitahukan Anda tentang perubahan pada jasa
                Kami;<br />
              </div>
              <div class="text_g">
                iv.untuk memastikan bahwa konten dari Platform Kami disajikan
                dengan cara yang paling efektif bagi Anda.
              </div>
              <br />
            </div>
            <div class="text_g">
              b.Informasi yang Kami kumpulkan. Kami akan menggunakan informasi
              ini:
            </div>
            <div class="">
              i.untuk mengelola Platform dan operasi internal Kami, termasuk
              pencarian sumber masalah (troubleshooting), analisis data,
              pengujian, penelitian, serta tujuan-tujuan statistik dan survei
              lainnya; <br />
              ii.untuk memperbaiki Platform Kami sehingga konten dipastikan
              dapat disajikan dengan cara yang paling efektif untuk Anda;
              <br />
              iii.untuk memungkinkan Anda berpartisipasi dalam fitur interaktif
              layanan Kami, ketika Anda inginkan; <br />
              iv.sebagai bagian dari usaha dalam memastikan keselamatan dan
              keamanan Platform Kami; <br />
              v.untuk mengukur dan memahami efektivitas periklanan yang Kami
              lakukan kepada Anda dan pihak lain, serta menyajikan iklan produk
              dan jasa yang relevan bagi Anda; <br />
              vi.Untuk memberi masukan dan rekomendasi kepada Anda dan pengguna
              lain dalam Platform Kami mengenai produk dan jasa yang dapat
              menarik minat Anda dan mereka. <br />
            </div>
            <div class="text_g">
              c.Informasi yang Kami terima dari sumber lain. Kami dapat
              menggabungkan informasi yang Kami terima dari sumber lain dengan
              informasi yang Anda berikan dan informasi yang Kami kumpulkan.
              Kami dapat menggunakan informasi ini maupun informasi gabungan
              untuk tujuan yang diatur di atas (tergantung tipe informasi yang
              Kami terima).
            </div>
            <br />
            <br />
            <span class="">C. PENGUNGKAPAN INFORMASI PRIBADI</span>
            <br />
            <div class="text_g">
              Kami dapat membagi atau menyingkap Data Pribadi dengan anggota
              kelompok usaha Kami, yang melingkupi cabang dan anak perusahaan,
              serta perusahaan induk utama dan anak perusahaannya. Kami dapat
              membagi Data Pribadi dengan pihak ketiga, termasuk :
            </div>
            <div class="text_g">
              a.Mitra bisnis, pemasok, dan sub-kontraktor dalam penyelenggarakan
              kontrak yang Kami laksanakan dengan mereka atau Anda.
            </div>
            <div class="text_g">
              b.Pemasang iklan dan jaringan iklan yang membutuhkan data untuk
              memilih dan menawarkan iklan yang relevan bagi Anda dan pengguna
              lain. Kami tidak membuka informasi tentang individu yang dapat
              diidentifikasi, namun Kami dapat menyediakan mereka informasi
              agregat tentang pengguna Kami (misalnya informasi bahwa 500 pria
              berusia di bawah 30 tahun telah mengakses tautan iklan mereka pada
              hari tertentu). Kami juga dapat memberikan informasi agregat untuk
              membantu pemasang iklan dalam menjangkau target audiens tertentu
              (misalnya, perempuan di Jakarta Pusat). Kami dapat menggunakan
              data personal yang Kami kumpulkan untuk memenuhi permintaan
              pemasang iklan dengan menampilkan iklan mereka kepada target
              audiens tersebut.
            </div>
            <div class="text_g">
              c.Penyedia analisis dan mesin pencari yang membantu Kami untuk
              memperbaiki dan mengoptimasi Platform Kami.
            </div>
            <div class="text_g">
              Kami dapat mengungkap informasi kepada pihak ketiga:
            </div>
            <div class="text_g">
              a. Dalam situasi di mana Kami menjual atau membeli perusahaan
              dan/atau aset, Kami dapat menyingkap data kepada calon pembeli
              atau penjual dari perusahaan atau aset tersebut.
            </div>
            <div class="text_g">
              b. Jika PT Solusi Desain Bangsa atau perusahaan induknya atau
              aset-aset substansial yang terkait di dalamnya diperoleh oleh
              pihak ketiga, maka Data Personal yang dimiliki tentang pelanggan
              Kami akan menjadi salah satu aset yang dipindahtangankan.
            </div>
            <div class="text_g">
              c. Jika Kami berada di bawah tanggung jawab untuk menyingkap atau
              membagi data guna mematuhi kewajiban hukum dan perjanjian lain;
              atau melindungi hak, harta benda, maupun keamanan dari PT Ruang
              Raya Indonesia, pelanggan Kami, dan lain-lain. Hal ini mencakup
              penukaran informasi dengan perusahaan dan organisasi lain untuk
              tujuan perlindungan dari penipuan dan pengurangan risiko kredit.
            </div>
            <br />
            <span class="">D. PENYIMPANAN INFORMASI PRIBADI</span>
            <br />
            <div class="text_g">
              Seluruh informasi Pribadi yang Anda berikan kepada Kami disimpan
              di server yang aman. Semua transaksi pembayaran pada Platform akan
              dienkripsi. Dengan menyerahkan Informasi Pribadi Anda pada
              Platform, Anda menyetujui pengalihan, penyimpanan, serta
              pengolahan yang terjadi pada Platform Kami. Kami akan mengambil
              langkah-langkah dalam batas kewajaran yang diperlukan untuk
              memastikan bahwa Informasi Pribadi tersebut diperlakukan dengan
              aman dan sesuai dengan Kebijakan Privasi serta Peraturan yang
              Berlaku.
            </div>
            <div class="text_g">
              Seluruh Informasi Pribadi yang Anda berikan akan Kami simpan: (i)
              selama Anda masih menjadi pengguna dari Platform Kami dan (ii)
              setidaknya 5 (lima) tahun sejak tanggal di mana Anda berhenti
              menggunakan Platform Kami; atau (iii) sesuai dengan tujuan awal
              dari pengumpulan Informasi Pribadi tersebut.
            </div>
            <div class="text_g">
              Dalam situasi di mana Kami memberikan (atau Anda memilih) sebuah
              kata sandi (password) yang mengizinkan Anda untuk mengakses
              bagian-bagian tertentu pada Platform Kami, Anda bertanggung jawab
              untuk menjaga kerahasiaan kata sandi ini. Kami meminta Anda untuk
              tidak membagi kata sandi dengan siapa pun.
            </div>
            <div class="text_g">
              Mohon untuk dapat diperhatikan bahwa transmisi informasi melalui
              internet tidak sepenuhnya aman. Meskipun demikian, Kami akan
              berusaha sebaik mungkin untuk melindungi Informasi Pribadi
              tersebut. Kami tidak bisa menjamin keamanan data yang dikirimkan
              ke Platform Kami; risiko dari setiap transmisi menjadi tanggung
              jawab Anda. Begitu Kami menerima Informasi Pribadi Anda, Kami akan
              menggunakan prosedur yang ketat dan fitur keamanan untuk mencegah
              akses yang tidak diizinkan.
            </div>
            <br />
            <span class="">E. HAK ANDA</span>
            <br />
            <div class="text_g">
              Anda dapat memohon untuk penghapusan Informasi Pribadi Anda pada
              Platform atau menarik persetujuan Anda untuk setiap atau segala
              pengumpulan, penggunaan atau pengungkapan Informasi Pribadi Anda
              dengan memberikan kepada kami pemberitahuan yang wajar secara
              tertulis melalui detail kontak yang tercantum pada bagian J
              Kebijakan Privasi ini. Tergantung pada keadaan dan sifat
              permohonan yang Anda minta, Anda harus memahami dan mengakui bahwa
              setelah penarikan persetujuan atau permohonan penghapusan
              tersebut, Anda mungkin tidak lagi dapat menggunakan Platform.
              Penarikan persetujuan Anda dapat mengakibatkan penghentian Akun
              Anda atau hubungan kontraktual Anda dengan kami, dengan semua hak
              dan kewajiban yang muncul sepenuhnya harus dipenuhi. Setelah
              menerima pemberitahuan untuk menarik persetujuan untuk
              pengumpulan, penggunaan atau pengungkapan Informasi Pribadi Anda,
              Kami akan menginformasikan Anda tentang konsekuensi yang mungkin
              terjadi dari penarikan tersebut sehingga Anda dapat memutuskan
              apakah Anda tetap ingin menarik persetujuan atau tidak.
            </div>
            <div class="text_g">
              Anda dapat meminta kepada Kami untuk mengakses dan/atau mengoreksi
              Informasi Pribadi anda yang berada dalam kepemilikan dan
              penguasaan kami, dengan menghubungi kami di perincian yang
              disediakan di bawah ini.
            </div>
            <div class="text_g">
              Platform Kami dapat, dari waktu ke waktu, memuat link menuju dan
              dari situs-situs milik jaringan mitra, pemuat iklan, dan afiliasi
              lainnya. Jika Anda mengikuti link ke salah satu situs tersebut,
              mohon perhatikan bahwa situs-situs tersebut memiliki Kebijakan
              Privasi mereka sendiri dan bahwa Kami tidak bertanggung jawab atau
              memiliki kewajiban apa pun atas kebijakan-kebijakan tersebut.
              Mohon periksa kebijakan-kebijakan tersebut sebelum Anda
              menyerahkan informasi apa pun ke situs-situs tersebut.
            </div>
            <br />
            <span class="">F. KEBIJAKAN COOKIES</span>
            <div class="text_g">
              Ketika Anda menggunakan Platform, Kami dapat menempatkan sejumlah
              cookies pada browser Anda. Cookies adalah sebuah berkas digital
              kecil berisi huruf dan angka yang Kami simpan pada browser atau
              hard drive komputer Anda atas persetujuan Anda. Cookies mengandung
              informasi yang dipindahkan ke diska keras komputer Anda.
            </div>
            <div class="text_g">
              Cookies dapat digunakan untuk tujuan berikut: (1) mengaktifkan
              fungsi tertentu, (2) memberikan analisis, (3) menyimpan preferensi
              Anda; dan (4) memungkinkan pengiriman iklan dan pengiklanan
              berdasarkan perilaku. Beberapa cookies ini hanya akan digunakan
              jika Anda menggunakan fitur tertentu, atau memilih preferensi
              tertentu, sementara sebagian Cookies lain akan selalu digunakan.
            </div>
            <div class="text_g">
              Kami menggunakan cookies untuk alasan-alasan berikut:
            </div>
            <div class="text_g">
              a.Cookies dibutuhkan untuk pengoperasian Platform Kami. Ini
              termasuk, misalnya, Cookies yang memungkinkan Anda memasuki Area
              yang aman di Platform Kami, menggunakan keranjang belanja, ataupun
              menggunakan layanan penagihan eletronik.
            </div>
            <div class="text_g">
              b.Cookies memungkinkan Kami untuk mengenali dan menghitung jumlah
              pengunjung serta melihat bagaimana pengunjung bergerak di sekitar
              Platform Kami saat mereka menggunakannya. Ini membantu Kami
              memperbaiki cara kerja Platform Kami, misalnya, dengan memastikan
              pengguna menemukan apa yang mereka cari dengan mudah.
            </div>
            <div class="text_g">
              c.Cookies digunakan untuk mengenali Anda saat kembali ke Platform
              Kami. Ini memungkinkan Kami melakukan personalisasi terhadap
              konten Kami untuk Anda, menyapa Anda dengan nama, serta mengingat
              preferensi Anda (misalnya, pilihan bahasa atau wilayah Anda).
            </div>
            <div class="text_g">
              d.Cookies mencatat kunjungan Anda ke Platform Kami, halaman yang
              telah Anda kunjungi, serta tautan yang telah Anda ikuti. Kami akan
              menggunakan informasi ini untuk membuat Platform Kami serta iklan
              yang terpasang di dalamnya lebih relevan kepada minat Anda. Kami
              juga dapat membagi informasi ini dengan pihak ketiga untuk tujuan
              tersebut.
            </div>
            <div class="text_g">
              Mohon perhatikan bahwa pihak ketiga (termasuk, misalnya, jaringan
              periklanan dan penyedia jasa eksternal seperti jasa analisis lalu
              lintas web) juga dapat menggunakan Cookies ini, di mana Kami tidak
              memiliki kendali. Cookies ini cenderung membuat Platform Kami dan
              iklan yang ditampilkan di dalamnya lebih relevan dengan minat
              Anda, serta meningkatkan kinerja Platform Kami. Anda dapat
              menghapus Cookies dengan cara melakukan fungsi clear data pada
              mobile app maupun web browser Anda yang memungkinkan Anda untuk
              menolak pengaturan seluruh atau sebagian Cookies. Akan tetapi,
              Anda mungkin tidak dapat mengakses seluruh atau sebagian Platform
              Kami.
            </div>
            <br />
            <span class="">G. PENGAKUAN DAN PERSETUJUAN</span>
            <br />
            <div class="text_g">
              Dengan menyetujui Kebijakan Privasi, Anda dan/atau orang tua, wali
              atau pengampu Anda (dalam hal Anda berusia di bawah 18 (delapan
              belas) tahun) mengakui bahwa Anda telah membaca dan memahami
              Kebijakan Privasi ini dan menyetujui segala ketentuannya. Secara
              khusus, Anda setuju dan memberikan persetujuan kepada kami untuk
              mengumpulkan, menggunakan, membagikan, mengungkapkan, menyimpan,
              mentransfer, atau mengolah Informasi Pribadi anda sesuai dengan
              Kebijakan Privasi ini. Dalam hal Anda memberikan Informasi Pribadi
              yang berkaitan dengan individu lain (misalnya Informasi Pribadi
              yang berkaitan dengan pasangan anda, anggota keluarga, teman, atau
              pihak lain) kepada Kami, maka Anda menyatakan dan menjamin bahwa
              Anda telah memperoleh persetujuan dari individu tersebut untuk,
              dan dengan ini menyetujui atas nama individu tersebut untuk,
              pengumpulan, penggunaan, pengungkapan dan pengolahan Informasi
              Pribadi mereka oleh Kami.
            </div>
            <br />
            <span class="">H. MATERI PEMASARAN</span>
            <div class="text_g">
              Kami dan Mitra Kami dapat mengirimkan anda dan/atau orang tua,
              wali atau pengampu Anda pemasaran langsung, iklan, dan komunikasi
              promosi melalui aplikasi push-notification, pesan melalui
              Aplikasi, pos, panggilan telepon, layanan pesan singkat, email
              dan/atau aplikasi pesan lainnya (“Materi Pemasaran”) jika Anda
              telah setuju untuk berlangganan milis Kami, dan/atau setuju untuk
              menerima materi pemasaran dan promosi dari Kami. Anda dapat
              memilih untuk tidak menerima komunikasi pemasaran tersebut kapan
              saja dengan menghubungi Kami melalui detail kontak yang tercantum
              pada bagian J Kebijakan Privasi ini. Mohon perhatikan bahwa jika
              Anda memilih untuk keluar, Kami masih dapat mengirimi Anda
              pesan-pesan non-promosi, seperti tanda terima atau informasi
              tentang Akun Anda.
            </div>
            <br />
            <br />
            <span class="">I. PERUBAHAN DALAM KEBIJAKAN PRIVASI KAMI</span>
            <div class="text_g">
              Perubahan apa pun yang Kami lakukan terhadap Kebijakan Privasi
              Kami di masa depan akan diterbitkan melalui halaman ini dan,
              ketika dibutuhkan, diberitahukan kepada Anda melalui surat
              elektronik. Mohon kunjungi kembali halaman ini dari waktu ke waktu
              untuk melihat adanya pemutakhiran atau perubahan pada Kebijakan
              Privasi Kami.
            </div>
            <br />
            <br />
            <span class="">J. CARA MENGHUBUNGI KAMI</span>
            <br />
            <div class="text_g">
              Jika Anda memiliki pertanyaan mengenai Kebijakan Privasi ini atau
              Anda ingin mendapatkan akses dan/atau melakukan koreksi terhadap
              Informasi Pribadi Anda, silahkan dapat menghubungi Kami melalui
              admin@sekolahdesain.id.
            </div>
            <p></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.border-lg {
  border-radius: 1rem;
}
</style>
